<template>
    <div class="mb-24">
        <TransUnion
            :application="application"
            :property="{ id: propertyId }"
        >
        </TransUnion>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import TransUnion from '@/components/applicationService/Verification.vue';

export default {
    name: 'TransUnionEvaluation',
    components: { TransUnion },
    data() {
        return {
            application: null,
        };
    },

    computed: {
        ...mapGetters({
            getApplication: 'application/getApplication',
        }),

        propertyId() {
            return this.$route.params.propertyId || '';
        },
    },

    beforeMount() {
        if (this.getApplication) this.application = this.getApplication;
    },

    methods: {
        ...mapActions({
            setApplication: 'application/setApplication',
        }),
    },
};
</script>
