<template>
    <div class="w-full flex flex-col h-full">
        <loader
            class="z-50"
            :loading="loading"
            :backdrop="true"
        />
        <Page
            ref="page"
            title="Rental Application"
            :loading="loading"
        >
            <template v-slot:header-addition>
                <div class="w-full bg-white flex flex-col flex-1 p-4 my-4">
                    <h2 class="font-bold text-center my-4 text-xl">Identity verification process</h2>
                </div>
                <div
                    v-if="loading"
                    class="ml-4"
                >
                    <h1 class="title">loading the next step, one moment please.</h1>
                </div>
            </template>
            <!-- Identity Verification Question -->
            <section
                v-if="transunion && transunion.Questions && transunion.Questions.length"
                class="my-4 ml-4"
            >
                <h2 class="hint mb-2 font-bold">
                    Please answer the below questions to verify your identity for the required background check.
                </h2>
                <span
                    v-if="error"
                    class="error"
                >
                    {{ errorMessage }}
                </span>
                <div
                    v-if="isTransunionSet(transunion)"
                    class="tu-questions"
                >
                    <div
                        v-for="(question, index) in transunion.Questions"
                        :key="question.QuestionKeyName"
                        class="tu-question my-2"
                    >
                        <label>{{ `${index + 1}. ${question.QuestionDisplayName}` }}</label>
                        <div class="answers">
                            <div
                                v-for="(answer, idx) in question.Choices"
                                :key="answer.ChoiceKeyName"
                                class="answer"
                            >
                                <input
                                    :id="'q' + index + '_' + idx"
                                    type="radio"
                                    :value="answer"
                                    :name="'q' + index"
                                    class="mr-2 my-1"
                                    @change="selectAnswer(question.QuestionKeyName, answer.ChoiceKeyName)"
                                />
                                <label :for="'q' + index + '_' + idx">{{ answer.ChoiceDisplayName }}</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <p class="info">One moment, loading your identity verification questions.</p>
                </div>
                <div class="btn-wrapper my-4">
                    <button
                        class="btn btn--primary"
                        @click="next"
                    >
                        {{ !saving ? 'submit' : 'saving' }}
                    </button>
                </div>
            </section>
        </Page>

        <div class="w-full bg-white flex-col flex-1 flex fixed bottom-0 p-4 mx-4">
            <h2 class="font-bold text-start my-4 text-base">
                Application status:
                <span class="text-purple-600">{{ application_status }}</span>
            </h2>
        </div>
    </div>
</template>

<script>
import { isEmpty } from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import NotifyMixin from '@/mixins/NotifyMixin';
import Loader from '@/components/ui/Loader';
import Page from '@/components/ui/Page';
import getEnvVariable from '@/utils/getEnvVariable';
import {
    SOMETHING_WENT_WRONG,
    IDENTITY_VERIFICATION_ERROR,
    EVALUATION_ERROR,
    SAVING_ID_VERIFICATION_RESULTS_ERROR,
    UPDATE_APPLICATION_ERROR,
} from '@/utils/constants/leasing.js';
import { formatQuotedRentAmount } from '@/utils/Amount';

const baseURL = getEnvVariable('VUE_APP_INTEGRATIONS_SERVICE');

export default {
    name: 'TransunionVerification',
    components: {
        Loader,
        Page,
    },

    mixins: [NotifyMixin],
    props: {
        property: {
            type: Object,
            required: true,
        },

        application: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            currentApplication: null,
            answers: [],
            transunion: {},
            saving: false,
            error: false,
            errorMessage: '',
            loadQuestions: false,
            loading: true,
            application_status: 'Identity Verification',
        };
    },

    computed: {
        ...mapGetters({
            getApplication: 'application/getApplication',
            getDocumentInstance: 'application/getDocumentInstance',
            getBackgroundScreening: 'application/getBackgroundScreening',
            currentProfile: 'auth/currentProfile',
            getApplicationId: 'application/getApplicationId',
        }),
    },

    mounted() {
        if (this.getApplication) {
            this.setApplication({ ...this.getApplication, person_id: this.getApplication.persons?.[0].person_id });
            this.currentApplication = {
                ...this.getApplication,
            };
            this.updateApplicationRecord(
                'Identity verification',
                'Identity verification process has started',
                'Identity verification process starded'
            );
            this.runIdentityVerification();
        }
    },

    methods: {
        ...mapActions({
            setApplication: 'application/setApplication',
            setApplicationId: 'application/setApplicationId',
            clearState: 'application/clearState',
        }),

        formatQuotedRentAmount,

        goBack() {
            this.$router.push({ name: 'application_service.index' });
        },

        selectAnswer(question, answer) {
            const response = { QuestionKeyName: question, SelectedChoiceKeyName: answer };
            this.answers.map(answer => {
                if (answer.QuestionKeyName === response.QuestionKeyName) {
                    const index = this.answers.findIndex(answer => answer.QuestionKeyName === response.QuestionKeyName);
                    this.answers.splice(index, 1);
                }
            });
            this.answers.push(response);
        },

        restart() {
            this.clearState();
            this.currentAttempt = 1;
            this.$router.push({ name: 'application_service.index' });
        },

        next() {
            this.loading = true;
            this.saving = true;
            if (this.answers.length < this.transunion.Questions?.length) {
                this.error = true;
                this.errorMessage = 'We can not process your application until all the questions are answered.';
                this.loading = false;
                this.saving = false;
                setTimeout(() => {
                    this.error = false;
                }, 5000);
            } else {
                setTimeout(() => {
                    this.runEvaluation();
                }, 2500);
            }
        },

        async postData(url = '', data = {}) {
            let response = {};
            response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                mode: 'cors',
                cache: 'no-cache',
                credentials: 'same-origin',
                redirect: 'follow',
                referrerPolicy: 'no-referrer',
                body: JSON.stringify(data),
            });

            return response.json();
        },

        mapDate(currentDate = '', isScreening = false) {
            const date = moment(currentDate).format('L');
            const dateSplited = date.split('/');

            return isScreening
                ? `${dateSplited[2]}-${dateSplited[0]}-${dateSplited[1]}`
                : `${dateSplited[0]}${dateSplited[1]}${dateSplited[2]}`;
        },

        getPhoneNumber() {
            return (
                this.getDocumentInstance.applicant_cell_phone ||
                this.getDocumentInstance.applicant_home_phone ||
                this.getDocumentInstance.current_employer_phone ||
                this.getDocumentInstance.current_employer_supr_phone ||
                this.getDocumentInstance.current_owner_phone ||
                this.getDocumentInstance.emergency_contact_cell_phone ||
                this.getDocumentInstance.emergency_contact_home_phone ||
                this.getDocumentInstance.emergency_contact_work_phone
            );
        },

        /* Identity verification */
        runIdentityVerification() {
            this.loading = true;
            this.currentApplication &&
                this.getDocumentInstance &&
                this.getDocumentInstance.current_address &&
                this.postData(`${baseURL}/transunion/identity`, {
                    CustomParameters: [],
                    Applicant: {
                        FirstName: this.currentApplication.first_name || this.getDocumentInstance.applicant_first_name,
                        LastName: this.currentApplication.last_name || this.getDocumentInstance.applicant_last_name,
                        MiddleName: this.getDocumentInstance.applicant_middle_name ? this.getDocumentInstance.applicant_middle_name : '',
                        Suffix: '',
                        BirthDate: this.mapDate(this.currentApplication.birth_date || this.getDocumentInstance.applicant_birth_date, false),
                        Ssn: this.getDocumentInstance.applicant_ssn.replace(/-/g, ''),
                        Phone: this.getPhoneNumber().replace(/[^\d]/g, ''),
                        EmailAddress: this.getDocumentInstance.current_email,
                        IdentificationNumber: '',
                        IdentificationType: '',
                        IdentificationState: '',
                        Addresses: [
                            {
                                StreetAddress: this.getDocumentInstance.current_address,
                                City: this.getDocumentInstance.current_city,
                                State: this.getDocumentInstance.current_st,
                                PostalCode: this.getDocumentInstance.current_zip,
                                Country: 'USA',
                                AddressType: 'Current',
                            },
                        ],
                        CustomParameters: [],
                    },
                    DeviceSessionId: '',
                })
                    .then(response => {
                        let isStringError = typeof response.error === 'string' || response.error instanceof String;

                        if (isStringError) {
                            this.error = true;
                            this.errorMessage =
                                response.error === 'Internal Server Error' ? 'INTEGRATIONS: Network error' : IDENTITY_VERIFICATION_ERROR;
                            this.notifyError(this.errorMessage);
                            this.loading = false;
                            return;
                        }

                        switch (response.data.Result) {
                        case 'ApplicantQuestioned':
                            this.transunion = { ...response.data, Questions: [] };
                            if (!response.data?.Questions?.find(question => question.QuestionKeyName === 'IDM_Choice')) return;
                            this.verificationFailed = false;
                            this.answers.push({
                                QuestionKeyName: 'IDM_Choice',
                                SelectedChoiceKeyName: 'Security Questions',
                            });
                            this.runEvaluation();
                            break;
                        case 'Completed':
                            this.error = false;
                            this.errorMessage = '';
                            this.setApplication({
                                ...this.getApplication,
                                application_process_status: 'Background screening',
                            });
                            this.updateApplicationRecord(
                                'Identity verification',
                                'identity verification process - Completed',
                                'identity verification - Completed'
                            );
                            break;
                        case 'Fail':
                            this.transunion = response.data;
                            this.loading = false;
                            this.application_status = 'Contact Office';
                            this.setApplication({
                                ...this.getApplication,
                                application_process_status: 'Mitigation',
                            });
                            /* Call update application record to update status */
                            this.updateApplicationRecord(
                                'Mitigation',
                                'identity verification process - failed',
                                'identity verification - failed'
                            );
                            this.saveIdentityVerificationResults(response.data);
                            break;

                        default:
                            break;
                        }
                    })
                    .catch(() => {
                        this.loading = false;
                        this.verificationFailed = true;
                        this.error = true;
                        this.errorMessage = SOMETHING_WENT_WRONG;
                        this.notifyError(IDENTITY_VERIFICATION_ERROR);
                    });
        },

        /* Run evaluation */
        runEvaluation() {
            this.loading = true;
            this.postData(`${baseURL}/transunion/identity`, {
                Answers: this.answers,
                ReferenceNumber: this.transunion.ReferenceNumber,
                CustomParameters: [],
                DeviceSessionId: '',
            })
                .then(res => {
                    let isStringError = typeof res.error === 'string' || res.error instanceof String;

                    if (isStringError) {
                        this.error = true;
                        this.errorMessage = res.error === 'Internal Server Error' ? 'INTEGRATIONS: Network error' : EVALUATION_ERROR;
                        this.notifyError(this.errorMessage);
                        this.loading = false;
                        return;
                    }

                    this.answers = [];
                    this.transunion = res.data;

                    switch (res.data.Result) {
                    case 'Completed':
                        this.error = false;
                        this.errorMessage = '';
                        this.loading = true;
                        this.setApplication({
                            ...this.getApplication,
                            birth_date: this.getDocumentInstance.applicant_birth_date,
                            application_process_status: 'Background screening',
                        });
                        /* Call update application record to update status */
                        this.updateApplicationRecord(
                            'Identity verification',
                            'identity evaluation process - completed',
                            'identity evaluation - completed'
                        );
                        this.saveIdentityVerificationResults(res.data, true);
                        break;
                    case 'Fail':
                        this.loading = false;
                        this.setApplication({ ...this.getApplication, application_process_status: 'Mitigation' });
                        /* Call update application record to update status */
                        this.updateApplicationRecord(
                            'Mitigation',
                            'identity evaluation process - failed',
                            'identity evaluation - failed'
                        );
                        this.saveIdentityVerificationResults(res.data, true);
                        break;

                    default:
                        this.loading = false;
                        break;
                    }
                })
                .catch(() => {
                    this.saving = false;
                    this.loading = false;
                    this.verificationFailed = true;
                    this.errorMessage = SOMETHING_WENT_WRONG;
                    this.notifyError(EVALUATION_ERROR);
                });
        },

        /* Save identity verification results */
        saveIdentityVerificationResults(data, isFromEvaluation = false) {
            this.$leasingServiceDataProvider
                .create('identity_verification', {
                    data: {
                        person_id: this.getApplication.person_id,
                        reference_number: data.ReferenceNumber,
                        transunion_application_number: data.ApplicationNumber,
                        target_group: data.TargetGroup,
                        status: data.VerificationStatus,
                        result: data.Result,
                        file_type: data.FileType,
                        key_messages: data.KeyMessages,
                        fraud_alert_messages:
                            data.FraudAlertMessages && data.FraudAlertMessages?.length ? data.FraudAlertMessages[0] : 'no data',
                        ofac_screen: data.OfacScreen && data.OfacScreen?.length ? data.OfacScreen[0] : 'no data',
                        potential_risk: data.PotentialRisk ?? 'no data',
                        warning: data.VerificationDetails,
                    },
                })
                .then(() => {
                    if ((data.Result.toLowerCase() === 'completed' && isFromEvaluation) || data.Result.toLowerCase() === 'fail') {
                        this.$router.push({ name: 'application_service.confirmation' });
                    }
                })
                .catch(() => {
                    this.notifyError(SAVING_ID_VERIFICATION_RESULTS_ERROR);
                })
                .finally(() => (this.loading = false));
        },

        isTransunionSet(data) {
            return !isEmpty(data);
        },

        /* Update application record */
        updateApplicationRecord(
            process_status = 'In progress',
            event_description = 'The application has started',
            event_name = 'Application in progress'
        ) {
            const new_application = { ...this.getApplication };
            delete new_application.application_id;
            delete new_application.occupancy_id;
            delete new_application.person_id;
            const currentApplicant = new_application.persons?.find(person => person.contact_id === this.currentProfile.contactId);
            const currentParty = new_application.parties?.find(party => party.contact_id === this.currentProfile.contactId);
            delete new_application.persons;

            this.$leasingServiceDataProvider
                .patch('application', {
                    application_id: currentApplicant?.application[0]?.application.application_id ?? this.getApplicationId,
                    data: {
                        ...new_application,
                        occupancy_id: this.currentProfile.occupancyId,
                        first_name: this.getDocumentInstance.applicant_first_name,
                        last_name: this.getDocumentInstance.applicant_last_name,
                        email: this.getDocumentInstance.current_email,
                        expected_lease_duration: `${this.getApplication.expected_lease_duration}`,
                        quoted_rent_amount: this.formatQuotedRentAmount(this.getApplication.quoted_rent_amount),
                        contact_id: this.getApplication.contact_id ?? new_application.contact_id ?? this.currentProfile.contactId,
                        lease_id: this.currentProfile.occupancyId,
                        middle_name: this.getDocumentInstance.applicant_middle_name ?? '',
                        home_phone: this.getDocumentInstance.applicant_home_phone,
                        document_url: this.getDocumentInstance.document_url,
                        birth_date: this.getDocumentInstance.applicant_birth_date,
                        marital_status: this.mapMaritalStatus(this.getDocumentInstance.applicant_marital_status),
                        application_process_status: process_status,
                        event_description,
                        event_name,
                        sn: this.getDocumentInstance.applicant_ssn.replace(/-/g, ''),
                        coapplicants: this.mapCoApplicants(new_application),
                        occupants: this.mapOccupants(currentApplicant),
                        emergency_contact: this.mapEmergencyContact(currentApplicant),
                        pet: this.mapPets(currentApplicant),
                        vehicle: this.mapVehicles(currentApplicant),
                        submission_date: this.getDocumentInstance.updated_at,
                        current_address: this.getDocumentInstance.current_address ?? '',
                        current_city: this.getDocumentInstance.current_city ?? '',
                        current_st: this.getDocumentInstance.current_st ?? '',
                        current_zip: this.getDocumentInstance.current_zip ?? '',
                        employment_income: 1, // Default value
                        employment_status: this.getDocumentInstance.current_employer ? 1 : 4,
                        is_primary: currentParty?.is_primary,
                        person_role: currentApplicant?.person_role,
                    },
                })
                .then(() => this.setApplicationId(currentApplicant?.application[0]?.application.application_id ?? this.getApplicationId))
                .catch(() => {
                    this.notifyError(UPDATE_APPLICATION_ERROR);
                })
                .finally(() => (this.loading = false));
        },

        mapEmergencyContact(currentApplicant) {
            const emergencyContact =
                currentApplicant?.emergency_contact?.length &&
                currentApplicant?.emergency_contact[0] &&
                this.getDocumentInstance?.emergency_contact_name
                    ? {
                        person_id: currentApplicant?.emergency_contact[0]?.person_id,
                        contact_id: currentApplicant?.emergency_contact[0]?.contact_id,
                        name: this.getDocumentInstance?.emergency_contact_name,
                        email: this.getDocumentInstance.emergency_contact_email,
                        cell_phone: this.getDocumentInstance.emergency_contact_cell_phone,
                        home_phone: this.getDocumentInstance.emergency_contact_home_phone,
                        relation: this.getDocumentInstance.emergency_contact_relation,
                    }
                    : this.getDocumentInstance?.emergency_contact_name
                        ? {
                            name: this.getDocumentInstance?.emergency_contact_name,
                            email: this.getDocumentInstance?.emergency_contact_email,
                            cell_phone: this.getDocumentInstance.emergency_contact_cell_phone,
                            home_phone: this.getDocumentInstance.emergency_contact_home_phone,
                            relation: this.getDocumentInstance.emergency_contact_relation,
                        }
                        : null;

            return emergencyContact;
        },

        mapOccupants(currentApplicant) {
            const occupants = this.getDocumentInstance?.occupants?.filter(occupant => occupant.name !== null)?.length
                ? this.getDocumentInstance?.occupants
                    ?.filter(occupant => occupant.name !== null)
                    ?.map((occupant, i) => {
                        if (currentApplicant?.occupants?.length && currentApplicant?.occupants[i]?.person_id) {
                            if (
                                `${currentApplicant?.occupants[i]?.first_name} ${currentApplicant?.occupants[i]?.last_name}` ===
                                  occupant.name
                            ) {
                                return {
                                    name: occupant.name,
                                    relation: occupant.relation,
                                    birth_date: occupant.birth_date,
                                    person_id: currentApplicant.occupants[i]?.person_id,
                                    contact_id: currentApplicant.occupants[i]?.contact_id,
                                };
                            }
                        }
                        return {
                            name: occupant.name,
                            relation: occupant.relation,
                            birth_date: occupant.birth_date,
                        };
                    })
                : [];
            return occupants;
        },

        mapVehicles(currenApplicant) {
            const vehiclesDocInstance =
                this.getDocumentInstance?.vehicles && this.getDocumentInstance?.vehicles?.filter(vehicle => vehicle.make !== null)?.length
                    ? this.getDocumentInstance?.vehicles?.filter(vehicle => vehicle.make !== null)
                    : [];
            let vehicles = [];
            switch (true) {
            case vehiclesDocInstance?.length && !currenApplicant?.vehicles?.length:
                vehicles = vehiclesDocInstance.map(vehicle => {
                    return {
                        customer_id: this.getApplication.customer_id,
                        business_unit_id: this.getApplication.business_unit_id,
                        vehicle_make: vehicle.make,
                        vehicle_year: vehicle.year,
                        vehicle_model: vehicle.model,
                        vehicle_color: vehicle.color,
                        license_plate_region: vehicle.license_st,
                        license_plate: vehicle.license_no ?? '',
                        primary_driver_party_id: currenApplicant?.person_id,
                        vehicle_type: 'n/a',
                    };
                });
                break;

            case !vehiclesDocInstance?.length && currenApplicant?.vehicles?.length:
                vehicles = currenApplicant?.vehicles?.length
                    ? currenApplicant?.vehicles.map(vehicle => {
                        return {
                            ...vehicle,
                            primary_driver_party_id: currenApplicant?.person_id,
                        };
                    })
                    : [];
                break;

            default:
                if (vehiclesDocInstance?.length > currenApplicant?.vehicles?.length) {
                    vehicles = vehiclesDocInstance.map((vehicle, i) => {
                        if (currenApplicant?.vehicles[i]?.vehicle_make === vehicle.make) {
                            return {
                                vehicle_id: currenApplicant?.vehicles[i]?.vehicle_id,
                                customer_id: this.getApplication.customer_id,
                                business_unit_id: this.getApplication.business_unit_id,
                                vehicle_make: vehicle.make,
                                vehicle_year: vehicle.year,
                                vehicle_model: vehicle.model,
                                vehicle_color: vehicle.color,
                                primary_driver_party_id: currenApplicant?.vehicles[i]?.primary_driver_party_id,
                                license_plate_region: vehicle.license_st,
                                vehicle_license_plate_text: vehicle.license_no ?? '',
                                vehicle_type: 'n/a',
                            };
                        }

                        return {
                            customer_id: this.getApplication.customer_id,
                            business_unit_id: this.getApplication.business_unit_id,
                            vehicle_make: vehicle.make,
                            vehicle_year: vehicle.year,
                            vehicle_model: vehicle.model,
                            vehicle_color: vehicle.color,
                            primary_driver_party_id: currenApplicant?.person_id,
                            license_plate_region: vehicle.license_st,
                            vehicle_license_plate_text: vehicle.license_no ?? '',
                            vehicle_type: 'n/a',
                        };
                    });
                } else {
                    vehicles = currenApplicant?.vehicles?.map((vehicleObj, i) => {
                        if (vehiclesDocInstance[i]?.make === vehicleObj.vehicle_make) {
                            return {
                                vehicle_id: vehicleObj.vehicle_id,
                                customer_id: this.getApplication.customer_id,
                                business_unit_id: this.getApplication.business_unit_id,
                                vehicle_make: vehiclesDocInstance[i].make,
                                vehicle_year: vehiclesDocInstance[i].year,
                                vehicle_model: vehiclesDocInstance[i].model,
                                vehicle_color: vehiclesDocInstance[i].color,
                                primary_driver_party_id: vehicleObj.primary_driver_party_id,
                                license_plate_region: vehiclesDocInstance[i].license_st,
                                vehicle_license_plate_text: vehiclesDocInstance[i].license_no ?? '',
                                vehicle_type: 'n/a',
                            };
                        }
                        return {
                            vehicle_id: vehicleObj.vehicle_id,
                            customer_id: this.getApplication.customer_id,
                            business_unit_id: this.getApplication.business_unit_id,
                            vehicle_make: vehicleObj.vehicle_make,
                            vehicle_year: vehicleObj.vehicle_year,
                            vehicle_model: vehicleObj.vehicle_model,
                            vehicle_color: vehicleObj.vehicle_color,
                            primary_driver_party_id: currenApplicant?.person_id,
                            license_plate_region: vehicleObj.license_plate_region,
                            vehicle_license_plate_text: vehicleObj.vehicle_license_plate_text ?? '',
                        };
                    });
                }
                break;
            }

            return vehicles;
        },

        mapPets(currenApplicant) {
            const petsDocInstance =
                this.getDocumentInstance?.pets && this.getDocumentInstance?.pets?.filter(pet => pet.name !== null)?.length
                    ? this.getDocumentInstance?.pets?.filter(pet => pet.name !== null)
                    : [];

            let pets = [];
            switch (true) {
            case petsDocInstance?.length && !currenApplicant?.pets?.length:
                pets = petsDocInstance.map(pet => {
                    return {
                        customer_id: this.getApplication.customer_id,
                        business_unit_id: this.getApplication.business_unit_id,
                        pet_name: pet.name,
                        pet_weight: pet.weight,
                        pet_breed: pet.breed,
                        pet_owner_party_id: currenApplicant?.person_id,
                        pet_species: null,
                        pet_sex: null,
                    };
                });
                break;

            case !petsDocInstance?.length && currenApplicant?.pets?.length:
                pets = currenApplicant?.pets?.length ? currenApplicant?.pets : [];
                break;

            default:
                if (petsDocInstance?.length > currenApplicant?.pets?.length) {
                    pets = petsDocInstance.map((pet, i) => {
                        if (currenApplicant?.pets[i]?.pet_name === pet.name) {
                            return {
                                pet_id: currenApplicant?.pets[i]?.pet_id,
                                customer_id: this.getApplication.customer_id,
                                business_unit_id: this.getApplication.business_unit_id,
                                pet_name: pet.name,
                                pet_weight: pet.weight,
                                pet_breed: pet.breed,
                                pet_owner_party_id: currenApplicant?.pets[i]?.pet_owner_party_id,
                                pet_species: currenApplicant?.pets[i]?.pet_species,
                                pet_sex: currenApplicant?.pets[i]?.pet_sex,
                            };
                        }

                        return {
                            customer_id: this.getApplication.customer_id,
                            business_unit_id: this.getApplication.business_unit_id,
                            pet_name: pet.name,
                            pet_weight: pet.weight,
                            pet_breed: pet.breed,
                            pet_owner_party_id: currenApplicant?.person_id,
                            pet_species: null,
                            pet_sex: null,
                        };
                    });
                } else {
                    pets = currenApplicant?.pets?.map((petObj, i) => {
                        if (petsDocInstance[i]?.name === petObj.pet_name) {
                            return {
                                pet_id: petObj.pet_id,
                                customer_id: this.getApplication.customer_id,
                                business_unit_id: this.getApplication.business_unit_id,
                                pet_name: petsDocInstance[i].name,
                                pet_weight: petsDocInstance[i].weight,
                                pet_breed: petsDocInstance[i].breed,
                                pet_owner_party_id: petObj.pet_owner_party_id,
                                pet_species: petObj.pet_species,
                                pet_sex: petObj.pet_sex,
                            };
                        }
                        return {
                            pet_id: petObj.pet_id,
                            customer_id: this.getApplication.customer_id,
                            business_unit_id: this.getApplication.business_unit_id,
                            pet_name: petObj.pet_name,
                            pet_weight: petObj.pet_weight,
                            pet_breed: petObj.pet_breed,
                            pet_owner_party_id: currenApplicant?.person_id,
                            pet_species: petObj.pet_species,
                            pet_sex: petObj.pet_sex,
                        };
                    });
                }
                break;
            }
            return pets;
        },

        mapCoApplicants(application) {
            let co_applicants =
                application.persons
                    ?.filter(
                        person => person.person_role.toLowerCase() === 'applicant' && person.contact_id !== this.currentProfile.contactId
                    )
                    ?.map(applicant => {
                        return {
                            person_id: applicant.person_id,
                            name: `${applicant.first_name} ${applicant.last_name}`,
                            email: applicant.email,
                        };
                    }) ?? [];

            const coApplicantsDocInstance = this.getDocumentInstance?.co_applicants?.filter(applicant => applicant.name !== null) ?? [];

            switch (true) {
            case coApplicantsDocInstance?.length && !co_applicants?.length:
                co_applicants = coApplicantsDocInstance;
                break;
            default:
                if (coApplicantsDocInstance?.length > co_applicants?.length) {
                    co_applicants = coApplicantsDocInstance.map((applicant, i) => {
                        if (co_applicants[i]?.email === applicant.email) {
                            return {
                                person_id: co_applicants[i].person_id,
                                name: `${co_applicants[i].first_name} ${co_applicants[i].last_name}`,
                                email: co_applicants[i].email,
                            };
                        }

                        return {
                            name: coApplicantsDocInstance.name,
                            email: coApplicantsDocInstance.email,
                        };
                    });
                }
                break;
            }
            return co_applicants;
        },

        mapMaritalStatus(status) {
            const marital_status = {
                SINGLE: 'Single',
                MARRIED: 'Married',
            }[status];
            return marital_status;
        },
    },
};
</script>

<style scoped>
.title {
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 1rem;
}
.error {
    background: #ffe8e3;
    border: 1px solid #e0b4a9;
    padding: 0.5rem;
    font-size: 0.75rem;
    display: block;
    color: #931b00;
    border-radius: 4px;
    margin-top: 8px;
}
.info {
    padding-top: 4rem;
}
.containers {
    margin-left: 15px;
}
</style>
